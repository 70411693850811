/* Improved styling for building blocks components */
.bb-container {
  margin-bottom: 1rem;
  position: relative;
}

/* Hide redundant model components */
.bb-container ~ div > h3 {
  display: none !important;
}

.bb-container ~ div > div {
  display: none !important;
}

.bb-model-card {
  padding: 1rem;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  margin-bottom: 1rem;
  transition: all 0.2s;
}

.bb-model-card:hover {
  box-shadow: 0 3px 8px rgba(0,0,0,0.15);
  transform: translateY(-2px);
}

.bb-header-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.bb-model-title {
  font-weight: 600;
  color: #333;
  display: flex;
  align-items: center;
}

.bb-help-icon {
  margin-left: 0.25rem;
  color: #888;
}

.bb-progress-section {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.bb-progress-bar {
  height: 6px;
  flex-grow: 1;
  background-color: #e0e0e0;
  border-radius: 3px;
  overflow: hidden;
  margin-right: 0.5rem;
}

.bb-progress-fill {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.3s ease;
}

.bb-progress-fill-warning {
  background-color: #ff9800;
}

.bb-progress-fill-danger {
  background-color: #f44336;
}

.bb-status-text {
  font-size: 0.75rem;
  color: #666;
}

.bb-content-box {
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 4px;
  border: 1px solid #eee;
}

.bb-undefined-content {
  color: #d32f2f;
  font-style: italic;
}

.bb-action-button {
  margin-top: 0.5rem;
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  background-color: white;
  border: 1px solid #1976d2;
  color: #1976d2;
  border-radius: 4px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
}

.bb-action-button:hover {
  background-color: #f0f7ff;
}

.bb-action-icon {
  margin-right: 0.25rem;
}

/* Building Blocks Component Styles */

.building-blocks-accordion {
  width: 100%;
}

.building-blocks-accordion .accordion-button {
  box-shadow: none !important;
  border: none !important;
  background-color: #f8f9fa !important;
}

.building-blocks-accordion .accordion-button:not(.collapsed) {
  background-color: #f8f9fa !important;
  color: #2c3e50;
}

.building-blocks-accordion .accordion-button:focus {
  box-shadow: none !important;
  border-color: transparent !important;
}

.building-blocks-accordion .accordion-button::after {
  background-size: 20px;
  transition: transform 0.3s ease;
}

/* For the model cards */
.MuiPaper-root {
  transition: box-shadow 0.3s ease;
}

.MuiPaper-root:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

/* For the progress indicators */
.MuiLinearProgress-root {
  transition: all 0.4s ease-out;
}

/* Special styling for Robot Model */
.MuiPaper-root.robot-model {
  border-left: 4px solid #673ab7;
}

.MuiLinearProgress-root.robot-progress .MuiLinearProgress-bar {
  background-color: #673ab7 !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .building-blocks-accordion .accordion-button {
    padding: 0.5rem 1rem;
  }
  
  .MuiGrid-container {
    margin-top: 0.5rem;
  }
} 