@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.sidebar ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.sidebar ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.HSM-state-text::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.HSM-state-text::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.HSM-Conditions::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.HSM-Conditions::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.HSM-state-text p {
  max-height: 100%;
  padding-left: 10px;
  padding-top: 20px;
  padding-right: 10px;
  padding-bottom: 2px;
  font-weight: bold;
  margin-bottom: 0;
}

.HSMView {
  text-align: center;
  display: inline-table;
}

/* adjusts padding for all edit-mode transitionH arrows based on level. */
.comptask .task .compskill .skill .transitionH {
  padding-top: 0px;
}
.comptask .task .compskill .transitionH {
  padding-top: 2px;
}

.comptask .task .transitionH {
  padding-top: 2px;
}
.comptask .transitionH {
  padding-top: 2px;
}

/* removes padding from all states except the first state in HSMView table. */
.HSMView tr:not(:first-child) td .level1 {
  padding-top: 0px;
}

.HSMView tr:not(:first-child) td .level2 {
  padding-top: 0px;
}

.HSMView tr:not(:first-child) td .level3 {
  padding-top: 0px;
}

.HSMView tr:not(:first-child) td .level4 {
  padding-top: 0px;
}

.viewmode {
  text-align: center;
  margin-bottom: 1em;
}

.HSM-Wrapper {
  display: flex;
  justify-content: center;
  margin-left: 5rem;
  position: relative;
  z-index: 2;
}

/* HSM Tree View Styles */
.hsm-tree-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 0;
  gap: 0.75rem;
}

.tree-view-container {
  width: 100%;
  position: relative;
}

.hsm-tree-node {
  margin-bottom: 0.2rem;
  position: relative;
}

.hsm-tree-node-header {
  display: flex;
  align-items: flex-start;
  position: relative;
  gap: 10px;
}

.hsm-tree-state-container {
  width: 100%;
}

.hsm-tree-state-container .HSM-Wrapper {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

/* Style for vertical lines connecting tree items */
.hsm-tree-children {
  position: relative;
}

.hsm-tree-children:before {
  content: '';
  position: absolute;
  top: 0;
  left: 12px;
  height: 100%;
  width: 1px;
  background-color: #ccc;
  z-index: 1;
}

/* Special case for activity's children to align properly */
.hsm-tree-node .hsm-tree-children[style*="padding-left: 0"] {
  padding-left: 0;
}

.hsm-tree-node .hsm-tree-children[style*="padding-left: 0"]:before {
  display: none;
}

/* Adapt HSM state styling for tree view */
.hsm-tree-view .HSM-state {
  padding: 0px 15px 0px 0px;
  width: 100% !important;
  margin-bottom: 0.5rem;
  position: relative;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.hsm-tree-view .HSM-state-text {
  width: calc(100% - 170px);
  max-width: calc(100% - 80px);
  padding-right: 10px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  min-height: 10px;
}

.hsm-tree-view .HSM-state-text p {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

/* Ensure text doesn't overflow into tools area */
.hsm-tree-view .HSM-Wrapper {
  display: flex;
  justify-content: flex-start;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

/* Better positioning for tools to avoid overlap */
.hsm-tree-view .tools {
  bottom: 2px;
  right: 5px;
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  padding: 3px 6px;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 4px;
  z-index: 10;
  max-width: 160px;
  flex-wrap: wrap;
}

/* Make tool icons more visible in tree view, ensure they have good color contrast */
.hsm-tree-view .HSM-state .tools svg,
.hsm-tree-view .HSM-state .tools span svg {
  color: #333;
  fill: currentColor;
  transition: transform 0.2s ease;
  width: 20px;
  height: 20px;
}

.hsm-tree-view .HSM-state .tools svg:hover,
.hsm-tree-view .HSM-state .tools span svg:hover {
  transform: scale(1.2);
}

.hsm-tree-view .HSM-state.cactivity .tools svg,
.hsm-tree-view .HSM-state.cactivity .tools span svg {
  color: #fff;
}

.hsm-tree-view .HSM-state.cactivity {
  width: 100% !important;
  border-left: 4px solid rgb(32 56 100);
  margin-top: 0.5rem;
}

.hsm-tree-view .HSM-state.comptask {
  width: 100% !important;
  border-left: 2px solid rgb(189 215 238);
}

.hsm-tree-view .HSM-state.task {
  width: 100% !important;
  border-left: 4px solid rgb(255 242 204);
}

.hsm-tree-view .HSM-state.compskill {
  width: 100% !important;
  border-left: 4px solid #c5c4c4;
}

.hsm-tree-view .HSM-state.skill {
  width: 100% !important;
  border-left: 4px solid #cfcece;
  background-color: #e0efff;
  border-color: #7fb1de;
}

.hsm-tree-view .image-box, 
.hsm-tree-view .video-box {
  max-width: calc(100% - 40px);
  margin-top: 5px;
  margin-bottom: 20px;
}

.HSM-Conditions {
  background-color: #656565;
  overflow-y: scroll;
  width: 80%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: fixed;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0px 10px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.conditionsTextArea {
  resize: none;
  width: 300px;
}

/* .animatable {
  position: absolute;
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  animation: 0.75s cubic-bezier(1, -0.01, 0.12, 0.8) 0.1s 1 reveal forwards;
}

.animatable::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: inherit;
  animation: 0.75s cubic-bezier(1, -0.01, 0.12, 0.8) 1.75s 1 hideBlack forwards;
}

@keyframes reveal {
  from {
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@keyframes hideBlack {
  from {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  to {
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }
} */

.conditions-text {
  max-height: 275px;
  padding: 10px 0px;
}

.HSMContainer {
  display: flex;
  align-content: flex-end;
}

.vertArrow {
  display: flex;
  align-items: center;
  height: 20vh;
}

.precond-text {
  padding-bottom: 1rem;
}

.flex {
  display: flex;
}

.flex-w100 {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.invis {
  opacity: 0%;
}

.HSM-state {
  color: #000000;
  display: inline-flex;
  flex-direction: column;
  border-radius: 10px;
  z-index: 1 !important;
  padding: 0px 15px 0px 15px;
  position: relative;
  min-height: 50px;
}
.HSM-state.cactivity {
  width: 60vw;
  color: #ffffff;
  background-color: rgb(32 56 100);
}

.HSM-state.comptask {
  width: 58vw;
  background-color: rgb(189 215 238);
  z-index: 3;
}

.HSM-state.task {
  width: 56vw;
  background-color: rgb(255 242 204);
}

.HSM-state.compskill {
  width: 54vw;
  background-color: #c5c4c4;
}

.HSM-state.skill {
  width: 52vw;
  background-color: #cfcece;
}

.HSM-state-text {
  justify-content: center;
  border-radius: 10px;
  resize: auto;
  text-align: left;
  flex: 1;
  min-width: 0;
  max-width: calc(100% - 130px);
  padding-right: 10px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.ownership {
  margin-top: 0px;
  margin-bottom: 6px;
  margin-left: 10px;
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 5;
}

.ownership-select {
  width: 100%;
  text-align-last: center;
  border-radius: 10px;
  color: #000000;
}

.ownership-select option {
  color: black;
}
.transitionH {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 25px;
  min-width: 100px;
  position: relative;
}

.transitionV {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 25px;
  min-height: 25px;
  position: relative;
}

.tools {
  color: #000000;
  padding: 2px 10px;
  width: auto;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  position: absolute;
  bottom: 2px;
  right: 5px;
  max-width: 160px;
  flex-wrap: wrap;
}

.toolbar {
  display: flex;
  height: 50px;
}

.toolbar button {
  margin-right: 20px;
}

.comments {
  max-height: 30% !important;
  overflow-y: scroll;
}

.papers {
  /* padding-bottom: 300px; */
  height: 30%;
  overflow-y: scroll;
}

button .centered-btn-text {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.btn-primary {
  border: none;
  border-radius: 8px;
  color: white;
  /* background-color: #0085A1; */
  background-color: #2374ab;
  /* width: 150px; */
  /* height: 30px; */
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 30%);
}

.btn-secondary {
  border: none;
  border-radius: 8px;
  color: white;
  background-color: #cc0202;
  /* width: 150px; */
  /* height: 30px; */
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 30%);
}

.btn-tertiary {
  border: none;
  border-radius: 8px;
  color: white;
  background-color: #ff782d;
  width: 150px;
  height: 30px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 30%);
}

.HSM-arrow {
  display: inline-flex;
  min-width: 100px;
  min-height: 20px;
  z-index: 1;
  position: relative;
}

/* Add explicit styling for arrow paths */
.HSM-arrow svg {
  width: 100%;
  height: 100%;
  min-width: 20px;
  min-height: 20px;
  display: block;
}

.HSM-arrow path,
.HSM-arrow line {
  stroke-width: 2px !important;
  stroke: #666 !important;
}

/* Enhanced transition styling */
.transitionH, .transitionV {
  position: relative;
}

.transitionH::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: var(--connection-color);
  z-index: 1;
}

.transitionV::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: var(--connection-color);
  z-index: 1;
}

.comptask .transitionH::before, 
.comptask .transitionV::before {
  background-color: #4d7faf;
}

.task .transitionH::before, 
.task .transitionV::before {
  background-color: #d4b84b;
}

.compskill .transitionH::before, 
.compskill .transitionV::before {
  background-color: #a8a8a8;
}

.skill .transitionH::before, 
.skill .transitionV::before {
  background-color: #b5b5b5;
}

/* Ensure composite task level arrows are visible */
.comptask .HSM-arrow, 
.task .HSM-arrow,
.compskill .HSM-arrow,
.skill .HSM-arrow {
  display: inline-flex;
  min-width: 100px;
  min-height: 20px;
  z-index: 1;
  position: relative;
}

/* Ensures spacing for transitions between components */
.HSM-Wrapper + .HSM-Wrapper {
  margin-top: 30px;
}

.ADLsViewer {
  flex-wrap: wrap;
  display: inline-flex;
  justify-content: space-around;
  column-gap: 5em;
}

/* .adl {
    width: 300px;
    height: 500px;
    padding: 20px 0px;
    border-radius: 8px;
    background-color: white;
    text-align: center;
    margin-right: 100px;
    margin-bottom: 50px;
} */

.adl {
  width: auto;
  /* height: 725px; */
  padding: 10px 0px 0px 0px;
  border-radius: 8px;
  background-color: rgba(33, 37, 41, 1);
  text-align: center;
  /* margin-right: 100px; */
  margin-bottom: 50px;
  box-shadow: 0 5px 10px rgb(0 0 0 / 30%);
  /* display: flex;
  flex-direction: row;
  align-items: center; */
}

.inside-adl {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #eaeaea;
}

.adl h1 {
  margin: 0px;
  margin-bottom: 10px;
  /* color: white; */
}

.activity-content p {
  text-align: left;
}

.adl .activities {
  /* background-color: #0085A1; */
  /* background-color: #eaeaea; */
  /* position: absolute; */
  /* height: 525px; */
  padding: 20px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

/* .adl-description {

} */

.activities .btn-primary {
  margin-bottom: 10px;
}

.activities .btn-secondary {
  margin-bottom: 10px;
}

.adl-content {
  background-color: #eaeaea;
  flex-grow: 1;
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
}

.adl svg {
  color: white;
}

.btn-light {
  color: white !important;
  background-color: #4472a0 !important;
  border-color: #4472a0 !important;
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light:active:focus .btn-light.active,
.open > .dropdown-toggle.btn-light {
  color: white !important;
  background-color: #f09d4a !important;
  border-color: #f09d4a !important;
}

.btn-contents {
  display: flex;
  align-items: center;
  align-content: center;
}

.avatars-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* flex-grow: 1; */
  background-color: #eaeaea;
  padding: 20px 60px 20px 60px;
  width: 100%;
}

.avatars-buttons button {
  margin-bottom: 10px;
}

.btn-text {
  flex-grow: 0.9;
  /* display: flex; */
}

.bg-eblue {
  background-color: #4472a0 !important;
}

.bg-egray {
  background-color: #eaeaea;
}

.navbar-light .navbar-brand {
  color: rgba(255, 255, 255, 0.9) !important;
}

/* .navbar-light .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.75) !important;
} */

.avatar-model {
  margin-bottom: 20px;
}

.adl-icon {
  margin-right: 10px;
}

#buildingBlocks {
  margin-bottom: 15px;
}

#basic-nav-dropdown {
  color: #212529 !important;
}

.activity {
  width: 75%;
  padding: 10px 0px 0px 0px;

  text-align: center;
}

.activity h3 {
  color: #313131;
  margin: 10px 0px;
}

.activity h4 {
  color: #313131;
  margin: 0px;
}

.activity-content {
  width: 100%;
  background-color: #eaeaea;
  padding: 10px;
}

.title-input {
  width: 25em;
}

.dropdown-menu {
  background-color: #ffffff !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #d6d6d6 !important;
}

.create-new {
  display: flex;
  justify-content: center;
}

.btn {
  box-shadow: 0px 3px 5px rgb(0 0 0 / 30%);
}

.header {
  text-align: center;
  font-weight: 800;
  font-size: 3.125rem;
  color: #404040;
}

.page-subheading {
  text-align: center;
  display: block;
  padding: 4rem;
  margin-block-start: -5.5rem;
}

.home-content {
  display: flex;
  flex-direction: column;
}

hr.small {
  max-width: 6.25rem;
  margin: 1rem auto;
  border-width: 0.25rem;
  border-color: inherit;
  border-radius: 0.1875rem;
}

.homepage {
  color: #404040;
}

.HSMContainerRoot {
  display: flex;
  justify-content: start;
}

.level0 {
  box-sizing: content-box;
}

.level1 {
  box-sizing: content-box;
}

.level1-active {
  box-sizing: content-box;
}

.level2 {
  box-sizing: content-box;
}

.level2-active {
  box-sizing: content-box;
}

.level3 {
  box-sizing: content-box;
}

.level3-active {
  box-sizing: content-box;
}

.legend-container {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 100;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));
}

.legend {
  display: flex;
  flex-direction: column;
  align-items: left;
  border-radius: 12px;
  background-color: white;
  color: #333;
  font-size: 14px;
  width: 280px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  z-index: 100;
}

.legend-header {
  padding: 10px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa;
}

.legend-container button {
  transition: all 0.2s ease-in-out;
}

.legend-container button:hover {
  transform: scale(1.05);
}

.legend-content {
  padding: 10px;
}

.legend-item {
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  overflow: hidden;
}

.legend-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.legend-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
}

.legend-definition {
  font-size: 11px;
  line-height: 1.4;
  opacity: 0.85;
  transition: max-height 0.3s ease-in-out, opacity 0.2s ease-in-out;
}

.legend-definition.max-h-0 {
  opacity: 0;
}

.legend-definition.max-h-24 {
  opacity: 1;
}

.legend-tooltip {
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 12px;
  padding: 8px 12px;
  background-color: #333;
  color: white;
  border-radius: 6px;
  font-size: 12px;
  line-height: 1.4;
  max-width: 250px;
  z-index: 110;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  opacity: 0;
  animation: fadeInFromRight 0.2s ease forwards;
}

.legend-tooltip::before {
  content: '';
  position: absolute;
  top: 50%;
  right: -6px;
  transform: translateY(-50%);
  border-width: 6px 0 6px 6px;
  border-style: solid;
  border-color: transparent transparent transparent #333;
}

@keyframes fadeInFromRight {
  from { opacity: 0; transform: translateY(-50%) translateX(10px); }
  to { opacity: 1; transform: translateY(-50%) translateX(0); }
}

.toolbar-end {
  width: 170px;
}

/* Style for media content in state boxes */
.image-box, .video-box {
  max-width: calc(100% - 40px);
  margin-top: 5px;
  margin-bottom: 20px;
}

.image-box img {
  max-width: 100%;
  border-radius: 6px;
}

.hsm-tree-toggle {
  background-color: #f5f5f5;
  border-radius: 50%;
  transition: all 0.2s ease;
  border: 1px solid #e0e0e0;
  flex-shrink: 0;
  margin-top: 15px;
  z-index: 5;
  margin-right: 8px !important;
}

.hsm-tree-toggle:hover {
  background-color: #e0e0e0;
}

/* Better visibility for comment and paper icons */
.comments-icon svg, 
.papers-icon svg {
  color: inherit;
  fill: currentColor;
  transition: transform 0.2s ease;
}

.comments-icon:hover svg, 
.papers-icon:hover svg {
  transform: scale(1.2);
}

/* Ensure tools are visible on all state types */
.HSM-state.cactivity .tools .comments-icon svg,
.HSM-state.cactivity .tools .papers-icon svg {
  color: white;
}

.HSM-state.comptask .tools .comments-icon svg,
.HSM-state.comptask .tools .papers-icon svg,
.HSM-state.task .tools .comments-icon svg,
.HSM-state.task .tools .papers-icon svg,
.HSM-state.compskill .tools .comments-icon svg,
.HSM-state.compskill .tools .papers-icon svg,
.HSM-state.skill .tools .comments-icon svg,
.HSM-state.skill .tools .papers-icon svg {
  color: #333;
}

/* Improved styling for state cards with media content */
.state-content-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12px;
  align-items: flex-start;
  justify-content: space-between;
}

/* Text content */
.HSM-state-text {
  justify-content: center;
  border-radius: 10px;
  resize: auto;
  text-align: left;
  flex: 1;
  min-width: 0;
  max-width: calc(100% - 140px);
  padding-right: 10px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

/* Responsive adjustments for small screens */
@media (max-width: 768px) {
  .HSM-state-text {
    max-width: calc(100% - 110px);
  }
}

@media (max-width: 576px) {
  .state-content-wrapper {
    gap: 5px;
  }
  
  .HSM-state-text {
    max-width: calc(100% - 100px);
  }
}

/* Adjustments for tree view */
.hsm-tree-view .state-content-wrapper {
  gap: 5px;
}

.hsm-tree-view .HSM-state-text {
  max-width: calc(100% - 110px);
}

/* Media sidebar with horizontal layout */
.media-sidebar {
  display: flex;
  flex-direction: row;
  gap: 6px;
  width: auto;
  max-width: 100%;
  align-self: flex-start;
  flex-wrap: wrap;
  margin-top: 3px;
  margin-bottom: 15px;
}

/* Media items as horizontal rectangles */
.media-square {
  width: 110px;
  height: 60px;
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* For tree view, use smaller rectangles */
.hsm-tree-view .media-sidebar {
  width: auto;
}

.hsm-tree-view .media-square {
  width: 100px;
  height: 60px;
}

/* Responsive adjustments for small screens */
@media (max-width: 768px) {
  .media-square {
    width: 100px;
    height: 60px;
  }
}

@media (max-width: 576px) {
  .media-sidebar {
    width: 100%;
  }
  
  .media-square {
    width: 90px;
    height: 55px;
  }
}

/* Make media squares look clickable */
.clickable-media {
  cursor: pointer;
  position: relative;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.clickable-media:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Media item labels and identifiers */
.media-square::before {
  content: attr(data-type);
  position: absolute;
  top: 0;
  left: 0;
  font-size: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 2px 5px;
  border-radius: 0 0 4px 0;
  z-index: 5;
}

.image-box::before {
  content: "Image";
}

.video-box::before {
  content: "Video";
}

/* Make the expand icon more visible */
.media-expand-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 18px;
  opacity: 0;
  transition: opacity 0.2s ease;
  background-color: rgba(0, 0, 0, 0.5);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 5;
}

.clickable-media:hover .media-expand-icon {
  opacity: 1;
}

/* Media Modal Styles */
.media-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999 !important;
  padding: 20px;
  transform: translateZ(0) !important;
  isolation: isolate !important;
  pointer-events: auto !important;
  will-change: transform;
}

body.modal-open {
  /* Removed overflow: hidden to allow page scrolling */
}

body.modal-open .HSM-state,
body.modal-open .HSM-Wrapper,
body.modal-open .tools,
body.modal-open .hsm-tree-view,
body.modal-open button:not(.media-modal-close):not(.tool-button):not([class*="upload"]):not([class*="btn"]) {
  pointer-events: none !important;
}

/* The modal itself and its content should remain clickable */
body.modal-open .media-modal-overlay,
body.modal-open .media-modal-content,
body.modal-open .media-modal-close {
  pointer-events: auto !important;
}

.media-modal-content {
  position: relative;
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 90vw;
  max-height: 90vh;
  overflow: hidden;
  z-index: 999999 !important;
  pointer-events: auto !important;
  transform: translateZ(0) !important;
  will-change: transform;
}

.image-modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999 !important;
  pointer-events: auto !important;
}

.video-modal-content {
  width: 60vw;
  height: auto;
  z-index: 999999 !important;
  pointer-events: auto !important;
  position: relative;
  padding-top: 33.75%; /* 16:9 Aspect Ratio (56.25% of 60%) */
  will-change: transform;
  max-height: 80vh;
}

.media-modal-image {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
  z-index: 999999 !important;
  position: relative;
  pointer-events: auto !important;
}

.media-modal-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 999999 !important;
  pointer-events: auto !important;
}

.media-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9999999 !important;
  transition: background-color 0.2s;
  pointer-events: auto !important;
}

.media-modal-close:hover {
  background-color: rgba(220, 53, 69, 0.9);
}

.comptask .transitionH,
.comptask .transitionV {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 25px;
  z-index: 1;
  position: relative;
}

.comptask .HSM-arrow {
  display: inline-flex;
  min-width: 100px;
  z-index: 1;
  position: relative;
}

/* Add button styling */
.add-state-button {
  opacity: 0.5;
  transition: all 0.2s ease;
  position: relative;
  z-index: 5;
}

.add-state-button:hover {
  opacity: 1;
  transform: scale(1.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.add-state-button:active {
  transform: scale(0.95);
}

/* Tooltip for add buttons */
.add-state-button::after {
  content: attr(data-tooltip);
  position: absolute;
  background: #333;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
  white-space: nowrap;
  left: 30px;
}

.add-state-button:hover::after {
  opacity: 1;
}

/* Add responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .video-modal-content {
    width: 85vw;
    padding-top: 47.8%; /* 16:9 Aspect Ratio for 85% width */
  }
}
