.App {
  margin: 50px 50px 0 50px;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.breadCrumbNav {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 15px;
}

.breadCrumbNav a {
  text-decoration: none;
  color: black;
}

.breadCrumbNav a:hover {
  text-decoration: underline;
  color: black;
}

.breadCrumbNav .activeBreadCrumb {
  text-decoration: underline;
  color: black;
}

.toolbar {
  margin-bottom: 15px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.scrollable-building-block {
  overscroll-behavior-y: contain;
}

.row-building-block {
  height: 50px;
}

.scrollable-div {
  background-color: #f1f1f1;
  height: 250px;
  overflow: auto;
  margin: 20px;
  text-align: justify;
  padding: 20px;
}

.avatars-display {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.BuildingBlockContainer {
  margin-bottom: 30px;
}

.homepage-text {
  text-align: center;
  margin-block-start: -6rem;
  margin-inline-start: 10px;
  margin-inline-end: 10px;
  margin-block-end: 3rem;
}

.button-center {
  margin: auto;
  margin-bottom: 30px;
}

.center {
  margin: auto;
}

.video-center {
  margin: auto;
}

#video1 {
  width: 47.5%;
  /* height: 300px;  */
  float: left;
  margin-right: 5%;
}

#video2 {
  width: 47.5%;
  /* height: 300px;  */
  float: left;
}

.accordion-button {
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius) !important;
  border-bottom-right-radius: var(
    --bs-accordion-inner-border-radius
  ) !important;
}
.accordion-button:not(.collapsed) {
  color: black !important;
  background-color: rgb(255, 255, 255) !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon) !important;
}
