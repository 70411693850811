@import '../node_modules/bootstrap/scss/functions';
@import 'node_modules/react-modal-video/scss/modal-video.scss';

// Required
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';

// Optional Bootstrap components here
// @import '../node_modules/bootstrap/scss/root';
@import '../node_modules/bootstrap/scss/reboot';
@import '../node_modules/bootstrap/scss/type';

$bs-dropdown-link-color: #ffffff;
$dropdown-link-color: #ffffff;



.navbar-login-singup {
    margin-left: 10px; 
   
  
  }

  .navbar-custom .nav-link:hover {
    
    background-color: #f1f1f1; 
  }
  
  .navbar-custom .nav-link:focus,
  .navbar-custom .nav-link:hover {
   
    background-color: #c3c3c3;
   
  }

  .container .row .dropdown .dropdown-menu {
    background-color: white !important;
    width: 100%;
  }
  
@media (max-width: 991.98px) {

    .navbar-collapse {
      margin-top: 10px;
      padding-bottom: 5%;
      background-color: #f8f9fa; 
      border-top: 1px solid #dee2e6;
      z-index: 50;
    }
    .navbar-custom {
        margin-left: auto;
      }
      
     
    .navbar-login-singup {
        margin-left: auto; 
      }
  }
